import gql from 'graphql-tag';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  CREATE,
  UPDATE,
  DELETE,
} from 'react-admin';

import {
  createOne,
  updateOne,
  deleteOne,
  getList,
  getMany,
  getOne,
} from '@ampli/admin-core';

const parseResponse = (data = {}) => {
  const thumbnail = `${data.baseUrl}/thumbnail`;
  const file = {
    src: `${data.baseUrl}/original`,
  };

  return {
    ...data,
    file,
    thumbnail,
  };
};

const parseRequestParams = ({ data = {} }) => {
  const file = (data.file || {}).rawFile;
  const tags = data.tags || [];
  const id = data.id;

  return {
    file,
    tags,
    id,
  };
};

const parseUpdateRequestParams = ({ data = {} }) => {
  const id = data.id;

  return {
    ...parseRequestParams({ data }),
    id,
  };
};

const ENTITY = `
  id
  tags
  formats
  baseUrl
`;

const LIST_QUERY = gql`
  query ListImages($pagination: Pagination, $filter: JSON, $sort: Sort) {
    connection: listImages(
      pagination: $pagination
      filter: $filter
      sort: $sort
    ) {
      data {
        ${ENTITY}
      }
      page {
        totalElements
      }
    }
  }
`;

const GET_ONE_QUERY = gql`
  query GetImage($id: ID!) {
    data: getImage(imageId: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetImages($ids: [ID!]!) {
    data: getImages (
      imageIds: $ids
    ) {
      ${ENTITY}
    }
  }
`;

const CREATE_ONE_QUERY = gql`
  mutation CreateImage($file: Upload!, $tags: [String!]) {
    data: uploadImage(documentFile: $file, tags: $tags) {
      ${ENTITY}
    }
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateImage($id: ID!, $file: Upload, $tags: [String!]) {
    data: updateImage(imageId: $id, documentFile: $file, tags: $tags) {
      ${ENTITY}
    }
  }
`;

const DELETE_ONE_QUERY = gql`
  mutation DeleteImage($id: ID!) {
    data: deleteImage(imageId: $id)
  }
`;

export default {
  [GET_LIST]: getList(LIST_QUERY, parseResponse),
  [GET_ONE]: getOne(GET_ONE_QUERY, parseResponse),
  [GET_MANY]: getMany(GET_MANY_QUERY, parseResponse),
  [CREATE]: createOne(CREATE_ONE_QUERY, parseResponse, parseRequestParams),
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [UPDATE]: updateOne(
    UPDATE_ONE_QUERY,
    parseResponse,
    parseUpdateRequestParams
  ),
};
