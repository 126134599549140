import React from 'react';
import {
  Edit,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { validateZipCode } from '@ampli/admin-core';

export const AffiliateEdit = (props) => (
  <Edit title="Edição de Núcleos de Apoio" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome do Polo" source="name" />
      <TextInput label="Nome do Responsável" source="ownerName" />
      <TextInput label="Telefone do responsável" source="ownerPhone" />
      <SelectInput
        label="Status"
        source="status"
        optionText="name"
        optionValue="value"
        choices={[
          { name: 'Ativo', value: 'ACTIVE' },
          { name: 'Não Ativo', value: 'INACTIVE' },
        ]}
      />
      <TextInput
        validate={validateZipCode}
        label="CEP"
        source="address.postalCode"
      />
      <TextInput label="Logradouro" source="address.street" />
      <TextInput label="Número" source="address.number" />
      <TextInput label="Complemento" source="address.complement" />
      <TextInput label="Bairro" source="address.neighbourhood" />
      <ReferenceInput
        label="Estado"
        source="address.stateId"
        reference="state"
        sort={{
          field: 'name',
          order: 'ASC',
        }}
      >
        <SelectInput optionText="acronym" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.address &&
          formData.address.stateId && (
            <ReferenceInput
              key={formData.address.stateId}
              filter={{ stateId: formData.address.stateId }}
              label="Cidade"
              source="address.cityId"
              reference="city"
              sort={{
                field: 'name',
                order: 'ASC',
              }}
              fullWidth
              {...rest}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
