import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { ApolloClient, from, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import { authStorage, compact, concat, map, GraphQLError, APIError, omitDeep } from '@ampli/utils';

var cacheCreator = function cacheCreator() {
  var cacheSettings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new InMemoryCache(_objectSpread({}, cacheSettings));
};

var omitTypenameLink = new ApolloLink(function (operation, forward) {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, ['__typename']);
  }

  return forward(operation);
});
var errorLink = onError(function (_ref) {
  var graphQLErrors = _ref.graphQLErrors,
      operation = _ref.operation,
      networkError = _ref.networkError;
  var context = operation.getContext();
  var response = context.response || {};

  if (graphQLErrors) {
    map(graphQLErrors, function (error) {
      console.error(new GraphQLError("".concat(error.code, ": ").concat(error.message, " (").concat(error.status, ")"), {
        requestHeaders: context.headers,
        responseStatus: response.status,
        operationName: operation.operationName
      }));
    });
  }

  if (networkError) {
    console.error(new APIError(networkError.message, {
      requestHeaders: context.headers,
      responseStatus: response.status,
      operationName: operation.operationName
    }));
  }
});
var authLink = setContext(function (_ref2) {
  var _ref2$headers = _ref2.headers,
      headers = _ref2$headers === void 0 ? {} : _ref2$headers;
  return authStorage.getItem('token').then(function (token) {
    return {
      headers: _objectSpread({
        authorization: token ? "Bearer ".concat(token) : 'Basic cG9ydGFsOnNlY3JldA=='
      }, headers)
    };
  });
});
export var clientCreator = function clientCreator(_ref3) {
  var graphqlUrl = _ref3.graphqlUrl,
      _ref3$links = _ref3.links,
      links = _ref3$links === void 0 ? [] : _ref3$links,
      _ref3$secure = _ref3.secure,
      secure = _ref3$secure === void 0 ? true : _ref3$secure,
      _ref3$cacheSettings = _ref3.cacheSettings,
      cacheSettings = _ref3$cacheSettings === void 0 ? {} : _ref3$cacheSettings;
  var link = from(compact(concat(links, [errorLink, secure && authLink, omitTypenameLink, createUploadLink({
    uri: graphqlUrl
  })])));
  return new ApolloClient({
    cache: cacheCreator(cacheSettings),
    link: link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  });
};