import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useVerifyUserPhoneNumber as useVerifyUserPhone } from '@ampli/services';

var useVerifyUserPhoneNumber = function useVerifyUserPhoneNumber(_ref) {
  var _ref$settings = _ref.settings,
      settings = _ref$settings === void 0 ? {} : _ref$settings,
      onSubmit = _ref.onSubmit,
      onError = _ref.onError,
      onCompleted = _ref.onCompleted;
  var grecaptcha = window.grecaptcha;

  var _useVerifyUserPhone = useVerifyUserPhone({
    onError: onError,
    onCompleted: onCompleted
  }),
      _useVerifyUserPhone2 = _slicedToArray(_useVerifyUserPhone, 2),
      mutate = _useVerifyUserPhone2[0],
      metadata = _useVerifyUserPhone2[1];

  var handleSubmit = function handleSubmit(values, captchaToken) {
    var userId = values.userId;
    mutate({
      variables: {
        userId: userId,
        captcha: {
          origin: 'web',
          token: captchaToken
        }
      }
    });
    onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(values);
  };

  var handleVerifyPhoneNumber = function handleVerifyPhoneNumber(values) {
    if (!grecaptcha) {
      throw new Error("Google reCaptcha doesn't exist.");
    }

    grecaptcha.ready(function () {
      grecaptcha.execute(settings.recaptchaSiteKey, {
        action: 'verifyUserPhoneNumber'
      }).then(function (token) {
        return handleSubmit(values, token);
      }).catch(console.error);
    });
  };

  return {
    handleVerifyPhoneNumber: handleVerifyPhoneNumber,
    metadata: metadata
  };
};

export default useVerifyUserPhoneNumber;