import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  DateInput,
  Edit,
  email,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from 'react-admin';
import { CityAndStateForm } from '../../components';

const validateEmail = email();

export const CandidateEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextField label="CPF" source="document" />
      <TextInput label="Nome" source="fullName" />
      <ReferenceInput
        label="Curso"
        source="courseId"
        reference="course"
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput
          source="name"
          optionText={(choice) => `${choice.name} - ${choice.courseType.name}`}
        />
      </ReferenceInput>
      <TextInput label="E-mail" source="email" validate={validateEmail} />
      <TextInput label="Celular" source="mobilePhone" />
      <FormDataConsumer {...props}>
        {(formDataProps) => (
          <CityAndStateForm
            formValueStateId={formDataProps.formData.leadCity.state.id}
            stateSource="leadCity.state.id"
            citySource="leadCityId"
            {...formDataProps}
          />
        )}
      </FormDataConsumer>
      <DateInput label="Data de Nascimento" source="birthDate" />
      <TextInput label="CEP" source="address.postalCode" />
      <TextInput label="Logradouro" source="address.street" />
      <TextInput label="Número" source="address.number" />
      <TextInput label="Complemento" source="address.complement" />
      <TextInput label="Bairro" source="address.neighbourhood" />
      <FormDataConsumer {...props}>
        {(formDataProps) => (
          <CityAndStateForm
            formValueStateId={
              formDataProps.formData.address &&
              formDataProps.formData.address.stateId
            }
            stateSource="address.stateId"
            citySource="address.cityId"
            allowEmpty={true}
            {...formDataProps}
          />
        )}
      </FormDataConsumer>
      <ArrayInput label="Necessidade Especiais" source="specialNeeds">
        <SimpleFormIterator disableRemove>
          <TextInput label="Descrição" source="description" />
        </SimpleFormIterator>
      </ArrayInput>
      <ReferenceInput
        allowEmpty
        label="Vendedor"
        source="sellerId"
        reference="seller"
        perPage={100}
      >
        <AutocompleteInput source="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
