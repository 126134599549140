import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useFormik } from '@ampli/utils';
import { useResetUserPassword } from '@ampli/services';

var useResetPasswordForm = function useResetPasswordForm(_ref) {
  var _ref$schemaOptions = _ref.schemaOptions,
      schemaOptions = _ref$schemaOptions === void 0 ? {} : _ref$schemaOptions,
      onSubmit = _ref.onSubmit,
      onError = _ref.onError,
      _onCompleted = _ref.onCompleted;

  var handleError = function handleError(error) {
    var errorMsg = /Validation id .+ is invalid/gi.test((error === null || error === void 0 ? void 0 : error.message) || '') ? 'Ocorreu um problema ao verificar seu telefone. ' + 'Volte ao passo anterior e refaça a verificação.' : 'Ocorreu um problema ao tentar criar uma senha. Tente novamente.';
    onError === null || onError === void 0 ? void 0 : onError(errorMsg);
  };

  var _useResetUserPassword = useResetUserPassword({
    onError: handleError,
    onCompleted: function onCompleted(response) {
      if (!response.errors && response.data) {
        _onCompleted === null || _onCompleted === void 0 ? void 0 : _onCompleted(response.data);
      } else {
        handleError();
      }
    }
  }),
      _useResetUserPassword2 = _slicedToArray(_useResetUserPassword, 2),
      mutate = _useResetUserPassword2[0],
      _useResetUserPassword3 = _useResetUserPassword2[1],
      data = _useResetUserPassword3.data,
      called = _useResetUserPassword3.called,
      loading = _useResetUserPassword3.loading,
      error = _useResetUserPassword3.error,
      schema = _useResetUserPassword3.schema;

  var handleSubmit = function handleSubmit(values) {
    var userId = values.userId;
    var validationId = values.validationId;
    var password = values.password;
    mutate({
      variables: {
        userId: userId,
        validationId: validationId,
        password: password
      }
    });
    onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
  };

  var formik = useFormik({
    initialValues: {
      username: '',
      userId: '',
      validationId: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: schema(schemaOptions),
    onSubmit: handleSubmit
  });
  return {
    formik: formik,
    loading: loading,
    called: called,
    error: error,
    data: data
  };
};

export default useResetPasswordForm;