import { GET_LIST, GET_ONE, GET_MANY } from 'react-admin';
import gql from 'graphql-tag';
import { getList, getMany, getOne } from '@ampli/admin-core';

const ENTITY = `
  id
  name
  courseType {
    name
  }
  courseStructureDocument
`;

const LIST_QUERY = gql`
  query ListCourses($pagination: Pagination, $sort: Sort) {
    connection: listCourses(pagination: $pagination, sort: $sort) {
      data {
        ${ENTITY}
      }
      page {
        totalElements
      }
    }
  }
`;

const GET_ONE_QUERY = gql`
  query GetCourse($id: ID!) {
    data: getCourse(courseId: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetCourses($ids: [ID!]!) {
    data: getCourses(
      courseIds: $ids
    ) {
      ${ENTITY}
    }
  }
`;

export default {
  [GET_LIST]: getList(LIST_QUERY),
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
};
