import * as Yup from 'yup';
import { isPasswordConfirm, isValidDocumentTest } from '../schema-tests';
var requiredMsg = 'Preenchimento obrigatório.';
var confirmPasswordRequiredMsg = 'As senhas devem ser iguais.';
export var validateUserSmsCodeSchema = function validateUserSmsCodeSchema() {
  return Yup.object({
    userId: Yup.string().required(requiredMsg),
    phoneNumberVerification: Yup.object({
      verificationId: Yup.string().required(requiredMsg),
      code: Yup.string().required(requiredMsg)
    })
  });
};
export var resetUserPasswordSchema = function resetUserPasswordSchema() {
  return Yup.object({
    userId: Yup.string().required(requiredMsg),
    validationId: Yup.string().required(requiredMsg),
    password: Yup.string().required(requiredMsg),
    confirmPassword: Yup.string().required(confirmPasswordRequiredMsg).test(isPasswordConfirm())
  });
};
export var verifyUserPhoneNumberSchema = function verifyUserPhoneNumberSchema() {
  return Yup.object({
    userId: Yup.string().required(requiredMsg)
  });
};
export var getAmpliUserLoginInfoSchema = function getAmpliUserLoginInfoSchema() {
  return Yup.object({
    username: Yup.string().required(requiredMsg)
  });
};