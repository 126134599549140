import { DateTime } from '@ampli/utils';
import React from 'react';
import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
} from 'react-admin';
import { format } from './common';

const used = (record) => {
  const usedAmount = record.initialAmount - record.amountAvailable;
  const usedPercentage = usedAmount / record.amountAvailable;

  return `${usedAmount} (${usedPercentage}%)`;
};

const daysPassed = (record) => {
  const difference = Math.floor(
    DateTime.local().diff(DateTime.fromISO(record.startDate), 'days').days
  );
  const text =
    difference === 0
      ? 'Iniciou hoje'
      : difference < 0
      ? `Falta ${difference * -1} dias para começar`
      : `Está ativo há ${difference} dia(s)`;
  return `${record.startDate} ${text}`;
};

const daysLeft = (record) => {
  const startDateDifference = Math.floor(
    DateTime.local().diff(DateTime.fromISO(record.startDate), 'days').days
  );
  const difference =
    Math.floor(DateTime.fromISO(record.expirationDate).diffNow('days').days) +
    1;
  const text =
    startDateDifference < 0
      ? 'Cupom ainda não iniciou'
      : difference === 0
      ? 'Termina hoje'
      : `Falta ${difference} dia(s) para finalizar`;
  return `${record.expirationDate} ${text}`;
};

export const CouponShow = (props) => (
  <Show title="Cupom" {...props}>
    <SimpleShowLayout>
      <TextField label="Código" source="code" />
      <TextField label="Descrição" source="description" />
      <FunctionField
        label="Desconto"
        render={(record) => format.discount(record)}
      />
      <NumberField
        label="Quantidate de cupom disponíveis"
        source="initialAmount"
      />
      <NumberField label="Disponível" source="amountAvailable" />
      <FunctionField label="Utilizado" render={(record) => used(record)} />
      <FunctionField
        label="Data de Início"
        render={(record) => daysPassed(record)}
      />
      <FunctionField
        label="Data de Expiração"
        render={(record) => daysLeft(record)}
      />
      <TextField label="Tipo" source="type" />
      <NumberField
        label="Quantidate de parcelas"
        source="numberOfInstalments"
      />
      <TextField label="Categoria" source="category" />
    </SimpleShowLayout>
  </Show>
);
