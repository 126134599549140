import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { uniqueId } from '@ampli/utils';
import * as coreResources from '../resources';
export var dataProviderCreator = function dataProviderCreator(_ref) {
  var client = _ref.client,
      resources = _ref.resources;
  return function (type, resource, params) {
    var queryId = uniqueId("".concat(resource, " ").concat(type, " "));

    try {
      var allResources = _objectSpread(_objectSpread({}, coreResources), resources);

      console.info(queryId, {
        params: params
      });
      return allResources[resource][type]({
        client: client,
        params: params,
        queryId: queryId
      }).then(function (response) {
        console.debug(queryId, {
          response: response
        });
        return response;
      }).catch(function (error) {
        console.debug(queryId, {
          error: error
        });
        throw error;
      });
    } catch (error) {
      console.debug(queryId, {
        error: error
      });
      throw new Error("Unsupported provider: ".concat(resource, ":").concat(type));
    }
  };
};