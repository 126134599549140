import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
var defaultMessages = {
  en: englishMessages
};
var defaultSettings = {
  messages: {},
  defaultLocale: 'en',
  settings: {}
};
export var i18nProviderCreator = function i18nProviderCreator() {
  var settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultSettings;
  var _settings$messages = settings.messages,
      messages = _settings$messages === void 0 ? {} : _settings$messages,
      _settings$defaultLoca = settings.defaultLocale,
      defaultLocale = _settings$defaultLoca === void 0 ? 'en' : _settings$defaultLoca,
      _settings$polyglotI = settings.polyglotI18nSettings,
      polyglotI18nSettings = _settings$polyglotI === void 0 ? {} : _settings$polyglotI;

  var finalMessages = _objectSpread(_objectSpread({}, defaultMessages), messages);

  return polyglotI18nProvider(function (locale) {
    return finalMessages[locale];
  }, defaultLocale, _objectSpread({
    allowMissing: true
  }, polyglotI18nSettings));
};