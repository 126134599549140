import gql from 'graphql-tag';
import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import {
  createOne,
  deleteOne,
  getList,
  getMany,
  getOne,
  updateOne,
} from '@ampli/admin-core';

const ENTITY = `
  code
  description
  discount
  initialAmount
  amountAvailable
  startDate
  expirationDate
  type
  numberOfInstalments
  category
`;

const LIST_QUERY = gql`
  query ListCoupons($pagination: Pagination, $filter: Filter, $sort: Sort) {
    connection: listCoupons(pagination: $pagination, filter: $filter, sort: $sort) {
      data {
        ${ENTITY}
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

const GET_ONE_QUERY = gql`
  query GetCoupon($id: ID!) {
    data: getCoupon(couponCode: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetCoupons($couponCodes: [ID!]!) {
    data: getCoupons (
      couponCodes: $couponCodes
    ) {
      ${ENTITY}
    }
  }
`;

const CREATE_ONE_QUERY = gql`
  mutation CreateCoupon($data: CouponInput!) {
    data: createCoupon(coupon: $data) {
      ${ENTITY}
    }
  }
`;

const createOneParseObject = (result) => {
  result.id = result.code;
  return result;
};

const createOneParseParams = (params) => {
  params.data.startDate =
    typeof params.data.startDate === 'string'
      ? params.data.startDate
      : params.data.startDate.toFormat('yyyy-MM-dd');
  return params;
};

const DELETE_ONE_QUERY = gql`
  mutation DeleteCoupon($id: ID!) {
    data: deleteCoupon(couponCode: $id)
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateCoupon($id: ID!, $data: CouponInput!) {
    data: updateCoupon(couponCode: $id, coupon: $data) {
      ${ENTITY}
    }
  }
`;

const updateParseObject = (result) => {
  return {
    id: result.code,
    ...result,
  };
};

const updateParseParams = (params) => {
  delete params.data.id;
  delete params.data.amountAvailable;
  return params;
};

const getListParseObject = (result) => {
  result.id = result.code;
  return result;
};

const getListParseParams = (params) => {
  params.sort.field = 'code';
  return params;
};

const getOneParseParams = (params) => {
  return params;
};

const getOneParseObject = (result) => {
  result.id = result.code;
  return result;
};

const getManyParseObject = (result) => {
  result.id = result.code;
  return result;
};

const getManyParseParams = (params) => {
  const couponCodes = params.ids.map((coupon) =>
    typeof coupon === 'string' ? coupon : coupon.code
  );

  return {
    couponCodes,
  };
};

export default {
  [CREATE]: createOne(
    CREATE_ONE_QUERY,
    createOneParseObject,
    createOneParseParams
  ),
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [GET_LIST]: getList(LIST_QUERY, getListParseObject, getListParseParams),
  [GET_MANY]: getMany(GET_MANY_QUERY, getManyParseObject, getManyParseParams),
  [GET_ONE]: getOne(GET_ONE_QUERY, getOneParseObject, getOneParseParams),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY, updateParseObject, updateParseParams),
};
