import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { forEach } from 'lodash';
export var i18nCreator = function i18nCreator(_ref) {
  var _ref$uses = _ref.uses,
      uses = _ref$uses === void 0 ? [] : _ref$uses,
      _ref$init = _ref.init,
      init = _ref$init === void 0 ? {} : _ref$init;
  i18n.use(Backend).use(LanguageDetector);
  forEach(uses, function (use) {
    return i18n.use(use);
  });
  i18n.init(_objectSpread({
    fallbackLng: {
      'pt-BR': ['pt', 'en'],
      'pt-PT': ['pt', 'en'],
      default: ['en']
    },
    debug: false,
    interpolation: {
      escapeValue: false
    }
  }, init));
  return i18n;
};