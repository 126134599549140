import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { find, map, flatten } from '@ampli/utils';
import { useQuery, useLazyQuery, useMutation, useServiceClient } from '../hooks';
import { GET_STUDENT_USER_LOGIN_INFO, FIND_STUDENT_COURSE_ENROLLMENTS, FIND_LEARNING_UNIT_ENROLLMENTS, FIND_LEARNING_OBJECTS_ENROLLMENTS, UPLOAD_STUDENT_DOCUMENT, GET_STUDENT_PAYMENT_PLAN, GET_STUDENT_CREDIT_CARDS, ENABLE_RECURRING_PAYMENT, DISABLE_RECURRING_PAYMENT, GET_STUDENT, DELETE_STUDENT_CREDIT_CARD, LIST_AVAILABLE_EXAMS, GET_SUBJECT_ENROLLMENT, GET_LAST_DONE_ASSIGNMENTS } from './queries';
import { getStudentUserLoginInfoSchema, uploadStudentDocumentSchema } from './schemas';
export var useGetStudent = function useGetStudent(studentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery = useQuery(GET_STUDENT, _objectSpread(_objectSpread({
    skip: !studentId
  }, options), {}, {
    variables: {
      studentId: studentId
    }
  })),
      data = _useQuery.data,
      rest = _objectWithoutProperties(_useQuery, ["data"]);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export var useGetStudentUserLoginInfo = function useGetStudentUserLoginInfo() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var client = useServiceClient('publicClient');

  var _useLazyQuery = useLazyQuery(GET_STUDENT_USER_LOGIN_INFO, _objectSpread(_objectSpread({}, options), {}, {
    client: client
  })),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      query = _useLazyQuery2[0],
      metadata = _useLazyQuery2[1];

  return [query, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: getStudentUserLoginInfoSchema
  })];
};
export var useFindStudentCourseEnrollments = function useFindStudentCourseEnrollments(studentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery2 = useQuery(FIND_STUDENT_COURSE_ENROLLMENTS, _objectSpread(_objectSpread({
    skip: !studentId
  }, options), {}, {
    variables: {
      studentId: studentId
    }
  })),
      data = _useQuery2.data,
      loading = _useQuery2.loading,
      rest = _objectWithoutProperties(_useQuery2, ["data", "loading"]);

  var courseEnrollments = loading || !data ? [] : data.data;
  return _objectSpread({
    data: courseEnrollments,
    loading: loading
  }, rest);
};
export var useFindLearningUnitEnrollments = function useFindLearningUnitEnrollments(subjectEnrollmentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery3 = useQuery(FIND_LEARNING_UNIT_ENROLLMENTS, _objectSpread(_objectSpread({
    skip: !subjectEnrollmentId
  }, options), {}, {
    variables: {
      subjectEnrollmentId: subjectEnrollmentId
    }
  })),
      data = _useQuery3.data,
      loading = _useQuery3.loading,
      rest = _objectWithoutProperties(_useQuery3, ["data", "loading"]);

  var learningUnitEnrollments = loading || !data ? [] : data.data;
  return _objectSpread({
    data: learningUnitEnrollments,
    loading: loading
  }, rest);
};
export var useFindLearningObjectEnrollments = function useFindLearningObjectEnrollments(subjectEnrollmentId, sectionEnrollmentId) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _useQuery4 = useQuery(FIND_LEARNING_OBJECTS_ENROLLMENTS, _objectSpread(_objectSpread({
    skip: !subjectEnrollmentId || !sectionEnrollmentId
  }, options), {}, {
    variables: {
      subjectEnrollmentId: subjectEnrollmentId
    }
  })),
      data = _useQuery4.data,
      loading = _useQuery4.loading,
      rest = _objectWithoutProperties(_useQuery4, ["data", "loading"]);

  var learningObjectEnrollments = [];

  if (!loading && data) {
    var learningUnitEnrollments = data.data;
    var sectionEnrollment = find(flatten(map(learningUnitEnrollments, function (_ref) {
      var sections = _ref.sections;
      return sections;
    })), {
      id: sectionEnrollmentId
    });
    learningObjectEnrollments = (sectionEnrollment === null || sectionEnrollment === void 0 ? void 0 : sectionEnrollment.learningObjects) || [];
  }

  return _objectSpread({
    data: learningObjectEnrollments,
    loading: loading
  }, rest);
};
export var useUploadStudentDocument = function useUploadStudentDocument() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useMutation = useMutation(UPLOAD_STUDENT_DOCUMENT, options),
      _useMutation2 = _slicedToArray(_useMutation, 2),
      mutation = _useMutation2[0],
      metadata = _useMutation2[1];

  return [mutation, metadata, uploadStudentDocumentSchema];
};
export var useGetStudentPaymentPlan = function useGetStudentPaymentPlan(studentId, courseId) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _useQuery5 = useQuery(GET_STUDENT_PAYMENT_PLAN, _objectSpread(_objectSpread({
    skip: !studentId || !courseId
  }, options), {}, {
    variables: {
      courseId: courseId,
      studentId: studentId
    }
  })),
      data = _useQuery5.data,
      rest = _objectWithoutProperties(_useQuery5, ["data"]);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export var useGetStudentCreditCards = function useGetStudentCreditCards(studentId, courseId) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _useQuery6 = useQuery(GET_STUDENT_CREDIT_CARDS, _objectSpread(_objectSpread({
    skip: !studentId || !courseId
  }, options), {}, {
    variables: {
      courseId: courseId,
      studentId: studentId
    }
  })),
      data = _useQuery6.data,
      rest = _objectWithoutProperties(_useQuery6, ["data"]);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};

var changeLocalRecurringStudentCreditCard = function changeLocalRecurringStudentCreditCard(id, newValue, _ref2) {
  var cache = _ref2.cache;
  var cacheId = cache.identify({
    __typename: 'StudentCreditCard',
    id: id
  });
  cache.modify({
    id: cacheId,
    fields: {
      isRecurring: function isRecurring() {
        return newValue;
      }
    }
  });
};

var disableLocalRecurringStudentCreditCard = function disableLocalRecurringStudentCreditCard(variables, _ref3) {
  var client = _ref3.client,
      cache = _ref3.cache;

  var _client$readQuery = client.readQuery({
    query: GET_STUDENT_CREDIT_CARDS,
    variables: variables
  }),
      data = _client$readQuery.data;

  var _iterator = _createForOfIteratorHelper(data),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var studentCreditCard = _step.value;
      changeLocalRecurringStudentCreditCard(studentCreditCard.id, false, {
        cache: cache
      });
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
};

export var useEnableRecurringPayment = function useEnableRecurringPayment() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useMutation3 = useMutation(ENABLE_RECURRING_PAYMENT, options),
      _useMutation4 = _slicedToArray(_useMutation3, 2),
      _mutate = _useMutation4[0],
      metadata = _useMutation4[1];

  var mutate = function mutate(_options) {
    var _options$variables = _options.variables,
        variables = _options$variables === void 0 ? {} : _options$variables,
        _update = _options.update,
        options = _objectWithoutProperties(_options, ["variables", "update"]);

    return _mutate(_objectSpread(_objectSpread({}, options), {}, {
      variables: variables,
      update: function update(cache, result) {
        var _result$data;

        if (result !== null && result !== void 0 && (_result$data = result.data) !== null && _result$data !== void 0 && _result$data.data && !(result !== null && result !== void 0 && result.errors)) {
          changeLocalRecurringStudentCreditCard(variables.recurring.studentCreditCardId, true, {
            cache: cache
          });
        }

        _update && _update(cache, result);
      }
    }));
  };

  return [mutate, metadata];
};
export var useDisableRecurringPayment = function useDisableRecurringPayment() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var client = useServiceClient();

  var _useMutation5 = useMutation(DISABLE_RECURRING_PAYMENT, options),
      _useMutation6 = _slicedToArray(_useMutation5, 2),
      _mutate = _useMutation6[0],
      metadata = _useMutation6[1];

  var mutate = function mutate(_options) {
    var _options$variables2 = _options.variables,
        variables = _options$variables2 === void 0 ? {} : _options$variables2,
        _update2 = _options.update,
        options = _objectWithoutProperties(_options, ["variables", "update"]);

    return _mutate(_objectSpread(_objectSpread({}, options), {}, {
      variables: variables,
      update: function update(cache, result) {
        var _result$data2;

        if (result !== null && result !== void 0 && (_result$data2 = result.data) !== null && _result$data2 !== void 0 && _result$data2.data && !(result !== null && result !== void 0 && result.errors)) {
          disableLocalRecurringStudentCreditCard(variables, {
            client: client,
            cache: cache
          });
        }

        _update2 && _update2(cache, result);
      }
    }));
  };

  return [mutate, metadata];
};

var deleteLocalStudentCreditCard = function deleteLocalStudentCreditCard(id, _ref4) {
  var cache = _ref4.cache;
  var cacheId = cache.identify({
    __typename: 'StudentCreditCard',
    id: id
  });
  cache.evict({
    id: cacheId
  });
  cache.gc();
};

export var useDeleteStudentCreditCard = function useDeleteStudentCreditCard() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useMutation7 = useMutation(DELETE_STUDENT_CREDIT_CARD, options),
      _useMutation8 = _slicedToArray(_useMutation7, 2),
      _mutate = _useMutation8[0],
      metadata = _useMutation8[1];

  var mutate = function mutate(_options) {
    var _options$variables3 = _options.variables,
        variables = _options$variables3 === void 0 ? {} : _options$variables3,
        _update3 = _options.update,
        options = _objectWithoutProperties(_options, ["variables", "update"]);

    return _mutate(_objectSpread(_objectSpread({}, options), {}, {
      variables: variables,
      update: function update(cache, result) {
        var _result$data3;

        if (result !== null && result !== void 0 && (_result$data3 = result.data) !== null && _result$data3 !== void 0 && _result$data3.data && !(result !== null && result !== void 0 && result.errors)) {
          deleteLocalStudentCreditCard(variables.studentCreditCardId, {
            cache: cache
          });
        }

        _update3 && _update3(cache, result);
      }
    }));
  };

  return [mutate, metadata];
};
export var useListAvailableExams = function useListAvailableExams(studentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery7 = useQuery(LIST_AVAILABLE_EXAMS, _objectSpread(_objectSpread({
    skip: !studentId
  }, options), {}, {
    variables: {
      studentId: studentId
    }
  })),
      data = _useQuery7.data,
      rest = _objectWithoutProperties(_useQuery7, ["data"]);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export var useLazyListAvailableExams = function useLazyListAvailableExams(studentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useLazyQuery3 = useLazyQuery(LIST_AVAILABLE_EXAMS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      studentId: studentId
    }
  })),
      _useLazyQuery4 = _slicedToArray(_useLazyQuery3, 2),
      query = _useLazyQuery4[0],
      _useLazyQuery4$ = _useLazyQuery4[1],
      data = _useLazyQuery4$.data,
      rest = _objectWithoutProperties(_useLazyQuery4$, ["data"]);

  return [query, _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest)];
};
export var useGetSubjectEnrollment = function useGetSubjectEnrollment(subjectEnrollmentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery8 = useQuery(GET_SUBJECT_ENROLLMENT, _objectSpread(_objectSpread({
    skip: !subjectEnrollmentId
  }, options), {}, {
    variables: {
      subjectEnrollmentId: subjectEnrollmentId
    }
  })),
      data = _useQuery8.data,
      rest = _objectWithoutProperties(_useQuery8, ["data"]);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export var useGetLastDoneAssignments = function useGetLastDoneAssignments(courseEnrollmentId) {
  var numberOfAssignments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _useQuery9 = useQuery(GET_LAST_DONE_ASSIGNMENTS, _objectSpread(_objectSpread({
    skip: !courseEnrollmentId
  }, options), {}, {
    variables: {
      courseEnrollmentId: courseEnrollmentId,
      numberOfAssignments: numberOfAssignments
    }
  })),
      data = _useQuery9.data,
      rest = _objectWithoutProperties(_useQuery9, ["data"]);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};