import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useLazyQuery, useMutation, useServiceClient } from '../hooks';
import { VALIDATE_USER_SMS_CODE, RESET_USER_PASSWORD, VERIFY_USER_PHONE_NUMBER, GET_AMPLI_USER_LOGIN_INFO } from './queries';
import { validateUserSmsCodeSchema, resetUserPasswordSchema, verifyUserPhoneNumberSchema, getAmpliUserLoginInfoSchema } from './schemas';
export var useValidateUserSmsCode = function useValidateUserSmsCode() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var client = useServiceClient('publicClient');

  var _useMutation = useMutation(VALIDATE_USER_SMS_CODE, _objectSpread(_objectSpread({}, options), {}, {
    client: client
  })),
      _useMutation2 = _slicedToArray(_useMutation, 2),
      mutate = _useMutation2[0],
      metadata = _useMutation2[1];

  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: validateUserSmsCodeSchema
  })];
};
export var useResetUserPassword = function useResetUserPassword() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var client = useServiceClient('publicClient');

  var _useMutation3 = useMutation(RESET_USER_PASSWORD, _objectSpread(_objectSpread({}, options), {}, {
    client: client
  })),
      _useMutation4 = _slicedToArray(_useMutation3, 2),
      mutate = _useMutation4[0],
      metadata = _useMutation4[1];

  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: resetUserPasswordSchema
  })];
};
export var useVerifyUserPhoneNumber = function useVerifyUserPhoneNumber() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var client = useServiceClient('publicClient');

  var _useMutation5 = useMutation(VERIFY_USER_PHONE_NUMBER, _objectSpread(_objectSpread({}, options), {}, {
    client: client
  })),
      _useMutation6 = _slicedToArray(_useMutation5, 2),
      mutate = _useMutation6[0],
      metadata = _useMutation6[1];

  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: verifyUserPhoneNumberSchema
  })];
};
export var useGetAmpliUserLoginInfo = function useGetAmpliUserLoginInfo() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var client = useServiceClient('publicClient');

  var _useLazyQuery = useLazyQuery(GET_AMPLI_USER_LOGIN_INFO, _objectSpread(_objectSpread({}, options), {}, {
    client: client
  })),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      query = _useLazyQuery2[0],
      metadata = _useLazyQuery2[1];

  return [query, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: getAmpliUserLoginInfoSchema
  })];
};