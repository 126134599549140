import { createMuiTheme } from '@material-ui/core/styles';
import { merge } from '@ampli/utils';
import { ColorBrandBase, ColorBrandLight, ColorAccentBase, ColorAccentLight, ColorSemanticError, ColorSemanticSuccess, ColorSemanticAlert, ColorTextDark, ColorTextLight, ColorNeutralLight } from '@ampli/ui';
var ampliTheme = {
  palette: {
    primary: {
      light: ColorAccentLight,
      main: ColorAccentBase,
      dark: ColorAccentBase,
      contrastText: '#fff'
    },
    secondary: {
      light: ColorBrandLight,
      main: ColorBrandBase,
      dark: ColorBrandBase,
      contrastText: '#fff'
    },
    error: {
      light: ColorSemanticError,
      main: ColorSemanticError,
      dark: ColorSemanticError,
      contrastText: '#fff'
    },
    warning: {
      light: ColorSemanticAlert,
      main: ColorSemanticAlert,
      dark: ColorSemanticAlert,
      contrastText: '#fff'
    },
    success: {
      light: ColorSemanticSuccess,
      main: ColorSemanticSuccess,
      dark: ColorSemanticSuccess,
      contrastText: '#fff'
    },
    text: {
      primary: ColorTextDark,
      secondary: ColorTextLight,
      disabled: ColorNeutralLight,
      hint: ColorTextDark
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  overrides: {
    MuiAutocomplete: {
      popper: {
        zIndex: 300000
      }
    }
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', '"Noto Sans"', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"', '"Noto Color Emoji"'].join(',')
  }
};
export var themeCreator = function themeCreator() {
  var theme = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return createMuiTheme(merge({}, ampliTheme, theme));
};