import { useFormik } from '@ampli/utils';
import useVerifyUserPhoneNumber from './use-verify-phone-number';

var useVerifyUserPhoneNumberForm = function useVerifyUserPhoneNumberForm(_ref) {
  var _ref$settings = _ref.settings,
      settings = _ref$settings === void 0 ? {} : _ref$settings,
      _ref$schemaOptions = _ref.schemaOptions,
      schemaOptions = _ref$schemaOptions === void 0 ? {} : _ref$schemaOptions,
      onSubmit = _ref.onSubmit,
      onError = _ref.onError,
      onCompleted = _ref.onCompleted;

  var _useVerifyUserPhoneNu = useVerifyUserPhoneNumber({
    settings: settings,
    onSubmit: onSubmit,
    onError: onError,
    onCompleted: onCompleted
  }),
      handleVerifyPhoneNumber = _useVerifyUserPhoneNu.handleVerifyPhoneNumber,
      _useVerifyUserPhoneNu2 = _useVerifyUserPhoneNu.metadata,
      data = _useVerifyUserPhoneNu2.data,
      called = _useVerifyUserPhoneNu2.called,
      loading = _useVerifyUserPhoneNu2.loading,
      error = _useVerifyUserPhoneNu2.error,
      schema = _useVerifyUserPhoneNu2.schema;

  var formik = useFormik({
    initialValues: {
      userId: ''
    },
    validationSchema: schema(schemaOptions),
    onSubmit: handleVerifyPhoneNumber
  });
  return {
    formik: formik,
    loading: loading,
    called: called,
    error: error,
    data: data
  };
};

export default useVerifyUserPhoneNumberForm;