import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useFormik } from '@ampli/utils';
import { useValidateUserSmsCode } from '@ampli/services';

var useValidateCodeForm = function useValidateCodeForm(_ref) {
  var _ref$schemaOptions = _ref.schemaOptions,
      schemaOptions = _ref$schemaOptions === void 0 ? {} : _ref$schemaOptions,
      onSubmit = _ref.onSubmit,
      onError = _ref.onError,
      _onCompleted = _ref.onCompleted;

  var _useValidateUserSmsCo = useValidateUserSmsCode({
    onError: onError,
    onCompleted: function onCompleted(_ref2) {
      var data = _ref2.data;

      if (data.status !== 'VALID') {
        onError === null || onError === void 0 ? void 0 : onError(data.status);
      } else {
        _onCompleted === null || _onCompleted === void 0 ? void 0 : _onCompleted(data);
      }
    }
  }),
      _useValidateUserSmsCo2 = _slicedToArray(_useValidateUserSmsCo, 2),
      mutate = _useValidateUserSmsCo2[0],
      _useValidateUserSmsCo3 = _useValidateUserSmsCo2[1],
      data = _useValidateUserSmsCo3.data,
      called = _useValidateUserSmsCo3.called,
      loading = _useValidateUserSmsCo3.loading,
      error = _useValidateUserSmsCo3.error,
      schema = _useValidateUserSmsCo3.schema;

  var handleSubmit = function handleSubmit(values) {
    var userId = values.userId;
    var phoneNumberVerification = values.phoneNumberVerification;
    mutate({
      variables: {
        userId: userId,
        phoneNumberVerification: phoneNumberVerification
      }
    });
    onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
  };

  var formik = useFormik({
    initialValues: {
      userId: '',
      phoneNumberVerification: {
        verificationId: '',
        code: ''
      }
    },
    validationSchema: schema(schemaOptions),
    onSubmit: handleSubmit
  });
  return {
    formik: formik,
    loading: loading,
    called: called,
    error: error,
    data: data
  };
};

export default useValidateCodeForm;