import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import gql from 'graphql-tag';
import { getOne, updateOne } from '../utils/resources';
var UPDATE_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation UpdateUserPassword(\n    $id: ID!\n    $oldPassword: String!\n    $newPassword: String!\n  ) {\n    data: updateUserPassword(\n      id: $id\n      oldPassword: $oldPassword\n      newPassword: $newPassword\n    )\n  }\n"])));
var GET_ONE_QUERY = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query Me {\n    data: me {\n      id\n    }\n  }\n"])));
export default {
  updatePassword: updateOne(UPDATE_QUERY),
  getUserId: getOne(GET_ONE_QUERY)
};