import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

export const AffiliateList = (props) => (
  <List title="Núcleos de Apoio" {...props}>
    <Datagrid rowClick="edit">
      <TextField label="Nome do Polo" source="name" />
      <TextField label="Nome do responsável" source="ownerName" />
      <TextField label="Telefone do responsável" source="ownerPhone" />
      <ReferenceField
        label="Cidade"
        source="address.cityId"
        reference="city"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Estado"
        source="address.stateId"
        reference="state"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="status" />
    </Datagrid>
  </List>
);
