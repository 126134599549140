import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { authStorage, useFormik } from '@ampli/utils';
import { useGetAmpliUserLoginInfo } from '@ampli/services';
import useVerifyUserPhoneNumber from './use-verify-phone-number';

var useGetAmpliUserLoginInfoForm = function useGetAmpliUserLoginInfoForm(_ref) {
  var _window;

  var _ref$settings = _ref.settings,
      settings = _ref$settings === void 0 ? {} : _ref$settings,
      _ref$schemaOptions = _ref.schemaOptions,
      schemaOptions = _ref$schemaOptions === void 0 ? {} : _ref$schemaOptions,
      onSubmit = _ref.onSubmit,
      onError = _ref.onError,
      onCompleted = _ref.onCompleted;
  var grecaptcha = (_window = window) === null || _window === void 0 ? void 0 : _window.grecaptcha;

  var _useVerifyUserPhoneNu = useVerifyUserPhoneNumber({
    settings: settings,
    onSubmit: onSubmit,
    onError: onError,
    onCompleted: onCompleted
  }),
      handleVerifyPhoneNumber = _useVerifyUserPhoneNu.handleVerifyPhoneNumber,
      verifyPhoneNumberMetadata = _useVerifyUserPhoneNu.metadata;

  var _useGetAmpliUserLogin = useGetAmpliUserLoginInfo({
    onError: onError,
    onCompleted: function onCompleted(_ref2) {
      var data = _ref2.data;
      authStorage.setItem('loginInfo', data).catch(console.error);
      handleVerifyPhoneNumber(data);
    }
  }),
      _useGetAmpliUserLogin2 = _slicedToArray(_useGetAmpliUserLogin, 2),
      getAmpliUserLoginInfo = _useGetAmpliUserLogin2[0],
      getAmpliUserLoginInfoMetadata = _useGetAmpliUserLogin2[1];

  var handleSubmit = function handleSubmit(values, captchaToken) {
    var username = values.username;
    getAmpliUserLoginInfo({
      variables: {
        username: username,
        captcha: {
          origin: 'web',
          token: captchaToken
        }
      }
    });
    onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(_objectSpread(_objectSpread({}, values), {}, {
      username: username
    }));
  };

  var formik = useFormik({
    initialValues: {
      username: ''
    },
    validationSchema: getAmpliUserLoginInfoMetadata.schema(schemaOptions),
    onSubmit: function onSubmit(values) {
      if (!grecaptcha) {
        throw new Error("Google reCaptcha doesn't exist.");
      }

      grecaptcha.ready(function () {
        grecaptcha.execute(settings.recaptchaSiteKey, {
          action: 'getAmpliUserLoginInfo'
        }).then(function (token) {
          handleSubmit(values, token);
          return token;
        }).catch(function (err) {
          console.error(err || 'Captcha UNKNOWN error');
        });
      });
    }
  });
  return {
    formik: formik,
    getAmpliUserLoginInfoMetadata: getAmpliUserLoginInfoMetadata,
    verifyPhoneNumberMetadata: verifyPhoneNumberMetadata
  };
};

export default useGetAmpliUserLoginInfoForm;