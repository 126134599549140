import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useQuery } from '../hooks';
import { GET_ASSIGNMENT } from './queries';
export var useGetAssignment = function useGetAssignment(assignmentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery = useQuery(GET_ASSIGNMENT, _objectSpread(_objectSpread({
    skip: !assignmentId
  }, options), {}, {
    variables: {
      assignmentId: assignmentId
    }
  })),
      data = _useQuery.data,
      rest = _objectWithoutProperties(_useQuery, ["data"]);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};