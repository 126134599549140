import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { tinyMceColorMap, tinyMceMenu, tinyMcePlugins, tinyMceToolbar } from './index';
var codeStyles = {
  'background-color': '#ededed',
  'border-radius': '4px',
  color: '#161616',
  'font-family': 'monospace'
};
export var tinyMceInit = {
  color_map: tinyMceColorMap,
  color_cols: 6,
  autoresize_bottom_margin: 0,
  menubar: true,
  invalid_styles: 'font-size',
  font_formats: '',
  fontsize_formats: '',
  menu: tinyMceMenu,
  plugins: tinyMcePlugins,
  toolbar: tinyMceToolbar,
  formats: {
    block_code: {
      block: 'div',
      styles: _objectSpread(_objectSpread({}, codeStyles), {}, {
        'margin-bottom': '16px',
        'overflow-x': 'scroll',
        padding: '16px',
        'white-space': 'nowrap'
      })
    },
    code: {
      inline: 'code',
      styles: _objectSpread(_objectSpread({}, codeStyles), {}, {
        padding: '4px'
      })
    }
  },
  style_formats_merge: true,
  style_formats: [{
    title: 'Block Code',
    format: 'block_code'
  }, {
    title: 'Inline Code',
    format: 'code'
  }]
};